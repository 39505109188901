import React, { Component } from 'react';

class SendEmailButton extends Component {
  render() {
    const { text, callback, isLoading, isDisabled } = this.props;

    return (
      <React.Fragment>
        <div className='view-more-btn mt--60 text-right'>
          <button
            disabled={isLoading || isDisabled}
            className={`rn-button-style--2 ${
              isDisabled && 'disabled'
            } btn-primary-color`}
            onClick={() => callback()}
          >
            {isLoading ? (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <span>{text}</span>
            )}
          </button>
        </div>
      </React.Fragment>
    );
  }
}
export default SendEmailButton;
