import Axios from 'axios';


// https://api.softmakers.pt
const api = Axios.create({
  baseURL: 'https://api.softmakers.pt',
  // baseURL: 'http://lvh.me:3333',
});

export default api;
