import React, { Component } from "react";
// import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import App from '../../../public/assets/images/icons/app.svg';
import Website from '../../../public/assets/images/icons/websites.svg';
import Outsorcing from '../../../public/assets/images/icons/outsorcing.svg';
import { ReactSVG } from 'react-svg'

const ServiceList = [
    {
        icon: App,
        title: 'Aplicativos e Softwares',
        description: 'Se já sabe o que precisa e está à procura de alguém para desenvolver o seu projeto, deu Match!'
    },
    {
        icon: Website,
        title: 'Sites e Ecommerce',
        description: 'Estamos sempre dispostos a encontrar uma forma de dar o nosso melhor para que possamos agregar valor ao seu negócio!'
    },
    {
        icon: Outsorcing,
        title: 'Outsorcing',
        description: 'Se precisa de mão de obra especializada em IT para desenvolver ou manter projetos, com certeza que o poderemos ajudar!'
    },
    // {
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // {
    //     icon: <FiUsers />,
    //     title: 'Marketing & Reporting',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // {
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]


class Service extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="#"> */}
                                <div className="service service__style--2  bg_color--5">
                                    <div className="icon">
                                        <ReactSVG src={val.icon} />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default Service;
