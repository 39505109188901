import React, { Component } from 'react';
import QuestionHeader from './QuestionHeader';

class SimpleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: '',
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(option) {
    this.setState({
      activeOption: option,
    });
  }

  render() {
    const { setState, question, options } = this.props;

    return (
      <React.Fragment>
        <div className='field col-sm'>
          <QuestionHeader texts={question} />
          <div className='select'>
            <div className='container select-field'>
              {options.map((option, index) => (
                <button
                  index={index}
                  className={`select-button ${
                    this.state.activeOption === option && 'activeSimpleButton'
                  } col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mr-3`}
                  onClick={() => {
                    setState(option, question);
                    this.toggleActive(option);
                  }}
                >
                  <p>{option}</p>
                </button>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SimpleSelect;
