import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/medphone.png" alt="Brand Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/mmestre.png" alt="Brand Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/portofresco.png" alt="Brand Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/shifti.png" alt="Brand Images"/>
                    </li>

                    <li>
                        <img src="/assets/images/brand/moda-center.png" alt="Brand Images"/>
                    </li>
                      <li>
                        <img src="/assets/images/brand/absoluta.png" alt="Brand Images"/>
                    </li>
                      <li>
                        <img src="/assets/images/brand/nara-leandro.png" alt="Brand Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/fire-link.png" alt="Brand Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/fbr.png" alt="Brand Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;