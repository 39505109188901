import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class error404 extends Component {
  render() {
    return (
      <>
        {/* Start Page Error  */}
        <div className='error-page-inner bg_color--4'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner'>
                  <h1 className='title theme-gradient'>404!</h1>
                  <h3 className='sub-title'>Pagina não encontrada</h3>
                  <span></span>
                  <div className='error-button'>
                    <Link className='rn-button-style--2 btn-solid' to='/'>
                      Voltar para home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Error  */}
      </>
    );
  }
}
export default error404;
