import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from 'react-icons/fa';

const SocialShare = [
  {
    Social: <FaFacebookF color='#000000' size={22} />,
    link: 'https://www.facebook.com/softmakerspt/',
  },
  {
    Social: <FaLinkedinIn color='#000000' size={22} />,
    link: 'https://www.linkedin.com/company/softmakersbr/?viewAsMember=true',
  },
  {
    Social: <FaInstagram color='#000000' size={22} />,
    link: 'https://www.instagram.com/softmakerspt/',
  },
  {
    Social: <FaTwitter color='#000000' size={22} />,
    link: 'https://www.twitter.com/MakersSoft',
  },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
  }

  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }

  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  }

  render() {
    const { logo, color = 'default-color' } = this.props;
    let logoUrl;
    if (logo === 'light') {
      logoUrl = (
        <img src='/assets/images/logo/logo-light.png' alt='Digital Agency' />
      );
    } else if (logo === 'dark') {
      logoUrl = (
        <img src='/assets/images/logo/logo-dark.png' alt='Digital Agency' />
      );
    } else if (logo === 'symbol-dark') {
      logoUrl = (
        <img
          src='/assets/images/logo/logo-symbol-dark.png'
          alt='Digital Agency'
        />
      );
    } else if (logo === 'symbol-light') {
      logoUrl = (
        <img
          src='/assets/images/logo/logo-symbol-light.png'
          alt='Digital Agency'
        />
      );
    } else {
      logoUrl = (
        <img
          src='/assets/images/logo/soft-logo-light.png'
          alt='Digital Agency'
        />
      );
    }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className='header-wrapper' id='header-wrapper'>
          <div className='header-left'>
            <div className='logo'>
              <a href='/'>{logoUrl}</a>
            </div>
          </div>
          <div className='header-right'>
            <nav className='mainmenunav d-lg-block'>
              <ul className='mainmenu'>
                <li>
                  <a href='#home'>Home</a>
                </li>
                <li>
                  <a href='#service'>Serviços</a>
                </li>
                <li>
                  <a href='#about'>Sobre nós</a>
                </li>
                {/* <li><a href='#projects'>Projetos</a></li> */}
                <div className='col-lg-4 col-12 menu-btn-field'>
                  <div className='inner'>
                    <ul className='social-share rn-sm-size d-flex liststyle'>
                      {SocialShare.map((val, i) => (
                        <li key={i}>
                          <a
                            className='social-share-header'
                            href={`${val.link}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {val.Social}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='menu-btn'>
                  <Link
                    className='rn-button-style--2 btn-primary-color'
                    to='/budget'
                  >
                    solicite um orçamento
                  </Link>
                </div>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className='humberger-menu d-block d-lg-none pl--20'>
              <span
                onClick={this.menuTrigger}
                className='menutrigger text-white'
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className='close-menu d-block d-lg-none'>
              <span onClick={this.CLoseMenuTrigger} className='closeTrigger'>
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
