import React, { Component } from 'react';

import App from '../../../public/assets/images/icons/newsletter.svg';
import Website from '../../../public/assets/images/icons/newsletter.svg';
import Outsorcing from '../../../public/assets/images/icons/chat.svg';
import { ReactSVG } from 'react-svg';

const ServiceList = [
  {
    icon: App,
    title: 'Blog ou Noticiais',
    option: 'Blog ou Noticiais',
  },
  {
    icon: Website,
    title: 'Newsletter',
    option: 'Newsletter',
  },
  {
    icon: Outsorcing,
    title: 'Live Chat',
    option: 'Live Chat',
  },
];

class ServiceFive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: '',
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(option) {
    this.setState({
      activeOption: option,
    });
  }

  render() {
    const { column, setState, question } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className='row'>
          {ServiceContent.map((val, i) => (
            <div className={`${column} mt--30`} key={i}>
              <button
                className={`select-button ${
                  this.state.activeOption === val.option && 'active'
                }`}
                onClick={() => {
                  setState(val.option, question);
                  this.toggleActive(val.option);
                }}
              >
                <div className='service service__style--2 d-flex align-items-center bg_color--5'>
                  <div className='icon margin-reset'>
                    <ReactSVG src={val.icon} />
                  </div>
                  <div className='content'>
                    <h3 className='title margin-reset'>{val.title}</h3>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceFive;
