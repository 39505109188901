import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiChevronLeft } from 'react-icons/fi';

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
  }

  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }

  render() {
    const { logo, showLeftArrow, goToPreviousQuestion } = this.props;
    let logoUrl;
    if (logo === 'light') {
      logoUrl = (
        <img src='/assets/images/logo/logo-light.png' alt='Digital Agency' />
      );
    } else if (logo === 'dark') {
      logoUrl = <img src='/assets/images/logo/logo.svg' alt='Digital Agency' />;
    } else if (logo === 'symbol-dark') {
      logoUrl = (
        <img
          src='/assets/images/logo/logo-symbol-dark.png'
          alt='Digital Agency'
        />
      );
    } else if (logo === 'symbol-light') {
      logoUrl = (
        <img
          src='/assets/images/logo/logo-symbol-light.png'
          alt='Digital Agency'
        />
      );
    } else {
      logoUrl = (
        <img
          src='/assets/images/logo/soft-logo-light.png'
          alt='Digital Agency'
        />
      );
    }

    return (
      <header className={`header-area formobile-menu header--transparent`}>
        <div className='header-wrapper' id='header-wrapper'>
          <div className='header-left'>
            {showLeftArrow && (
              <span>
                <button className='goBackButton' onClick={goToPreviousQuestion}>
                  <FiChevronLeft size={32} color='#3B5999' />
                </button>
              </span>
            )}
          </div>
          <div className='header-right'>
            <div className='logo'>{logoUrl}</div>
          </div>
          <div className='header-right'>
            <span>
              <Link to='/'>
                <FiX size={32} color='#3B5999' />
              </Link>
            </span>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
