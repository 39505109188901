import React, { Component } from 'react';
// import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import App from '../../../public/assets/images/icons/app.svg';
import Website from '../../../public/assets/images/icons/websites.svg';
import Outsorcing from '../../../public/assets/images/icons/outsorcing.svg';
import { ReactSVG } from 'react-svg';

const ServiceList = [
  {
    icon: App,
    title: 'Landing Page',
    option: 'Landing Page',
    description:
      'Site com, geralmente apenas uma página, voltado para converter visitantes em possíveis clientes (no qual chamamos de leads)',
  },
  {
    icon: Website,
    title: 'Site Institucional',
    option: 'Site Institucional',
    description:
      'Site voltado para divulgar as informações de uma empresa, além dos seus serviços, produtos e filosofia.',
  },
  {
    icon: Outsorcing,
    title: 'Ecommerce',
    option: 'Ecommerce',
    description:
      'Site na qual empresas oferecem e vendem seus serviços ou produtos. Os clientes acessam o site, escolhem os artigos para aquisição e recebem estes produtos, geralmente em casa.',
  },
  {
    icon: Website,
    title: 'Site Intranet',
    option: 'Site Intranet',
    description:
      'Site criado para ser assessado por um grupo expecíficos de pessoas, geralmente funcionários de uma empresa',
  },
];

class ServiceFour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: '',
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(option) {
    this.setState({
      activeOption: option,
    });
  }

  render() {
    const { column, setState, question } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className='row'>
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <button
                className={`select-button ${
                  this.state.activeOption === val.option && 'active'
                } text-left`}
                onClick={() => {
                  setState(val.option, question);
                  this.toggleActive(val.option);
                }}
              >
                <div className='service service__style--2  bg_color--5'>
                  <div className='icon'>
                    <ReactSVG src={val.icon} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceFour;
