import React from 'react';
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from 'react-icons/fa';
import logo from '../../../public/assets/images/logo/logo.png';

const SocialShare = [
  {
    Social: <FaFacebookF color='#3F5DA4' size={22} />,
    link: 'https://www.facebook.com/softmakerspt/',
  },
  {
    Social: <FaLinkedinIn color='#3F5DA4' size={22} />,
    link: 'https://www.linkedin.com/company/softmakersbr/?viewAsMember=true',
  },
  {
    Social: <FaInstagram color='#3F5DA4' size={22} />,
    link: 'https://www.instagram.com/softmakerspt/',
  },
  {
    Social: <FaTwitter color='#3F5DA4' size={22} />,
    link: 'https://www.twitter.com/MakersSoft',
  },
];

const FooterTwo = () => {
  return (
    <div className='footer-style-2 bg_color--5 ptb--30'>
      <div className='wrapper plr--50 plr_sm--20'>
        <div className='row align-items-center justify-content-start'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <div className='inner'>
              <div className='logo text-center text-sm mb_sm--20'>
                <a href='/'>
                  <img src={logo} alt='Logo images' width='70' height='40' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <div className='inner text-center'>
              <ul className='social-share rn-sm-size d-flex justify-content-center liststyle'>
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a
                      href={`${val.link}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
