import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import ServiceListThree from '../elements/service/ServiceListThree';
import ServiceListFour from '../elements/service/ServiceListFour';
import ServiceListFive from '../elements/service/ServiceListFive';
import HostingList from '../elements/selects/hosting';
import SocialMediaList from '../elements/selects/socialMedia';
import Header from '../component/header/HeaderSix';
import { FiChevronUp } from 'react-icons/fi';
import Helmet from '../component/common/Helmet';
import SimpleSelect from '../component/budget/SimpleSelect';
import SimpleSelectMultiSelection from '../component/budget/SimpleSelectMultiSelection';
import SimpleSelectTwo from '../component/budget/SimpleSelectTwo';
import SimpleSelectThree from '../component/budget/SimpleSelectThree';
import TextInput from '../component/budget/TextInput';
import QuestionHeader from '../component/budget/QuestionHeader';
import ContinueButton from '../component/budget/ContinueButton';
import SendEmailButton from '../component/budget/SendEmailButton';

import api from '../service/api';

class Budget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      chosenOptions: [],
      currentQuestion: {},
      step: 1,
      userInfo: {},
    };

    this.sendEmail = this.sendEmail.bind(this);
    this.upDateField = this.upDateField.bind(this);
    this.goToNextQuestion = this.goToNextQuestion.bind(this);
    this.goToPreviousQuestion = this.goToPreviousQuestion.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.checkIfItsTheLastQuestion = this.checkIfItsTheLastQuestion.bind(this);
    this.changeUserName = this.changeUserName.bind(this);
    this.changeUserEmail = this.changeUserEmail.bind(this);
    this.changeUserPhone = this.changeUserPhone.bind(this);
    this.checkIfAnswerIsCorrect = this.checkIfAnswerIsCorrect.bind(this);
  }

  async sendEmail() {
    try {
      this.setState({
        isLoading: true,
      });

      const data = [
        ...this.state.chosenOptions,
        this.state.currentQuestion,
      ].filter((value) => JSON.stringify(value) !== '{}');

      await api.post('/send-email', {
        data,
        userInfo: this.state.userInfo,
      });

      alert('Email enviado com sucesso!');

      this.setState({
        chosenOptions: [],
        currentQuestion: {},
        step: 1,
        userInfo: {},
      });

      this.props.history.push('greetings');
    } catch (error) {
      alert('Erro ao enviar email!');
    } finally {
      this.setState({ isLoading: false });
    }
  }

  checkIfItsTheLastQuestion() {
    const condition1 =
      this.state.chosenOptions[0]?.answer[0] ===
        'Aplicativos Mobile IOS e Android' && this.state.step === 6;

    const condition2 =
      this.state.chosenOptions[0]?.answer[0] === 'Sistemas e Softwares' &&
      this.state.step === 6;

    const condition3 =
      this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
      ['Site Intranet', 'Landing Page'].includes(
        this.state.chosenOptions[2]?.answer[0]
      ) &&
      this.state.step === 8;

    const condition4 =
      this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
      ['Site Institucional', 'Ecommerce'].includes(
        this.state.chosenOptions[2]?.answer[0]
      ) &&
      this.state.step === 10;

    return condition1 || condition2 || condition3 || condition4;
  }

  upDateField(newValue, question) {
    if (Array.isArray(newValue)) {
      this.setState({
        ...this.state,
        currentQuestion: { question, answer: newValue },
      });
    } else {
      this.setState({
        ...this.state,
        currentQuestion: { question, answer: [newValue] },
      });
    }
  }

  goToNextQuestion() {
    this.setState({
      ...this.state,
      chosenOptions: [...this.state.chosenOptions, this.state.currentQuestion],
      step: this.state.step + 1,
      currentQuestion: {},
    });
  }

  goToPreviousQuestion() {
    let chosenOptionsCopy = this.state.chosenOptions;
    chosenOptionsCopy.pop();

    this.setState({
      ...this.state,
      step: this.state.step - 1,
      currentQuestion: {},
      chosenOptions: chosenOptionsCopy,
    });
  }

  checkIfAnswerIsCorrect() {
    let isDisabled = true;

    if (
      this.state.currentQuestion.answer[0] === 'Email' &&
      this.state.userInfo?.email
    ) {
      isDisabled = false;
    }

    if (
      ['Ligação telefônica', 'Whatsapp'].includes(
        this.state.currentQuestion.answer[0]
      ) &&
      this.state.userInfo?.phone
    ) {
      isDisabled = false;
    }

    if (!this.state.userInfo?.userName) {
      isDisabled = true;
    }

    return isDisabled;
  }

  changeUserName(event) {
    this.setState({
      ...this.state,
      userInfo: { ...this.state.userInfo, userName: event.target.value },
    });
  }

  changeUserEmail(event) {
    this.setState({
      ...this.state,
      userInfo: { ...this.state.userInfo, email: event.target.value },
    });
  }

  changeUserPhone(event) {
    this.setState({
      ...this.state,
      userInfo: { ...this.state.userInfo, phone: event.target.value },
    });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Fragment>
        <Helmet pageTitle='SoftMakers' />

        <Header
          logo='dark'
          showLeftArrow={this.state.step >= 2}
          goToPreviousQuestion={this.goToPreviousQuestion}
        />

        <div className='container ptb--120'>
          {this.state.step === 1 && (
            <div className='row'>
              <div className='field col-sm'>
                <QuestionHeader
                  texts={[
                    `Olá`,
                    `Eu sou Claudio Santos, diretor da Softmakers PT, e vou te
                  ajudar a solicitar um orçamento super rápido e intuitivo.`,
                    `Para comerçarmos, me fala qual serviço/produto você deseja?`,
                  ]}
                />
                <div className='container'>
                  <ServiceListThree
                    setState={this.upDateField}
                    question={[
                      `Olá`,
                      `Eu sou Claudio Santos, diretor da Softmakers PT, e vou te ajudar a solicitar um orçamento super rápido e intuitivo.`,
                      `Para comerçarmos, me fala qual serviço/produto você deseja?`,
                    ]}
                    item='3'
                    column='col-12 col-sm-12 col-md-6 col-lg-4 text-left'
                  />
                </div>
                <ContinueButton
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </div>
            </div>
          )}

          {/* Start 1.0 flow */}
          {this.state.step === 2 &&
            this.state.chosenOptions[0]?.answer[0] ===
              'Aplicativos Mobile IOS e Android' && (
              <>
                <div className='row'>
                  <TextInput
                    question={[
                      `Na era da mobilidade, é praticamente impossível viver sem um smartphone, então é essencial que o seu negócio tenha um aplicativo mobile!`,
                      `Me conta mais sobre o seu negócio. Quais são os seus objetivos com o seu aplicativo?`,
                    ]}
                    setState={this.upDateField}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] ===
            'Aplicativos Mobile IOS e Android' &&
            this.state.step === 3 && (
              <div className='row'>
                <div className='field col-sm'>
                  <QuestionHeader
                    texts={[
                      `Obrigado!`,
                      `Você deseja que seu aplicativo esteja em quais lojas? Você pode selecionar mais de um, inclusive todos.`,
                    ]}
                  />
                  <div className='container'>
                    <HostingList
                      question={[
                        `Obrigado!`,
                        `Você deseja que seu aplicativo esteja em quais lojas? Você pode selecionar mais de um, inclusive todos.`,
                      ]}
                      setState={this.upDateField}
                      item='3'
                      column='col-12 col-sm-12 col-md-6 col-lg-4 text-left'
                    />
                  </div>
                  <ContinueButton
                    isDisabled={this.isEmpty(this.state.currentQuestion)}
                    text='continuar'
                    callback={() => this.goToNextQuestion()}
                  />
                </div>
              </div>
            )}

          {this.state.chosenOptions[0]?.answer[0] ===
            'Aplicativos Mobile IOS e Android' &&
            this.state.step === 4 && (
              <>
                <div className='row'>
                  <TextInput
                    question={[
                      `Obrigado pela resposta! É interessante entender que quanto mais lojas seu aplicativo, maior visibilidade se negócio terá`,
                      `Agora, você pode me dar alguns links ou nome de aplicativos como referências? podem ser até concorrentes.`,
                      `Não é obrigatório, mas referências ajudam muito a agilizar o desenvolvimento!`,
                    ]}
                    setState={this.upDateField}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] ===
            'Aplicativos Mobile IOS e Android' &&
            this.state.step === 5 && (
              <>
                <div className='row'>
                  <SimpleSelect
                    question={[
                      `Obrigado pela resposta! Referências nos ajuda a entender e atender às suas expectativas.`,
                      `Não vai demorar muito, eu prometo.`,
                      `O seu site vai suportar um ou mais idiomas?`,
                    ]}
                    setState={this.upDateField}
                    options={['Um idioma', 'Dois idiomas', 'Três ou mais']}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {/* End 1.0 flow */}

          {/* Start 2.0 flow */}
          {this.state.chosenOptions[0]?.answer[0] === 'Sistemas e Softwares' &&
            this.state.step === 2 && (
              <>
                <div className='row'>
                  <TextInput
                    question={[
                      `Me conta mais sobre o seu negócio. Quais são os seus objetivos com o seu aplicativo?`,
                    ]}
                    setState={this.upDateField}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] === 'Sistemas e Softwares' &&
            this.state.step === 3 && (
              <>
                <div className='row'>
                  <TextInput
                    question={[
                      `Obrigado!`,
                      `Agora, você pode me dar alguns links ou nome de aplicativos como referências? podem ser até concorrentes.`,
                      `Não é obrigatório, mas referências ajudam muito a agilizar o desenvolvimento!`,
                    ]}
                    setState={this.upDateField}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] === 'Sistemas e Softwares' &&
            this.state.step === 4 && (
              <>
                <div className='row'>
                  <SimpleSelectMultiSelection
                    question={[
                      `Obrigado pela resposta! Referências nos ajuda a entender e atender às suas expectativas.`,
                      `Não vai demorar muito, eu prometo.`,
                      `Você deseja que seu sistema/ software funcione em quais sistemas operacionais? Você pode selecionar mais de um, inclusive todos.`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      'Chromebook',
                      'Mac OS',
                      'Windows',
                      'Linux',
                      'Web',
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}
          {this.state.chosenOptions[0]?.answer[0] === 'Sistemas e Softwares' &&
            this.state.step === 5 && (
              <>
                <div className='row'>
                  <SimpleSelect
                    question={[
                      `Obrigado pela resposta!`,
                      `O seu sistema vai suportar um ou mais idiomas?`,
                    ]}
                    setState={this.upDateField}
                    options={['Um idioma', 'Dois idiomas', 'Três ou mais']}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {/* End 2.0 flow */}

          {/* Start 3.0 flow */}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            this.state.step === 2 && (
              <>
                <div className='row'>
                  <TextInput
                    question={[
                      `Seja para vender seus produtos, mostrar para o mundo seu trabalho, sua empresa, ou simplesmente para se expressar, um site será sua melhor escolha!`,
                      `Me conta mais sobre o seu negócio. Quais são os seus objetivos com o seu site?`,
                    ]}
                    setState={this.upDateField}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            this.state.step === 3 && (
              <div className='row'>
                <div className='field col-sm'>
                  <QuestionHeader
                    texts={[
                      `Obrigado`,
                      `Me fala agora qual tipo de site voce deseja fazer? Se o seu site possuir caracteristicas de vários tipos, escolha qual você acredita ser o tipo mais predominante.`,
                    ]}
                  />
                  <div className='container'>
                    <ServiceListFour
                      question={[
                        `Obrigado`,
                        `Me fala agora qual tipo de site voce deseja fazer? Se o seu site possuir caracteristicas de vários tipos, escolha qual você acredita ser o tipo mais predominante.`,
                      ]}
                      setState={this.upDateField}
                      item='4'
                      column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
                    />
                  </div>
                  <ContinueButton
                    text='continuar'
                    isDisabled={this.isEmpty(this.state.currentQuestion)}
                    callback={() => this.goToNextQuestion()}
                  />
                </div>
              </div>
            )}

          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            this.state.step === 4 && (
              <>
                <div className='row'>
                  <SimpleSelectThree
                    question={[
                      `Obrigado pela resposta! Conversão de visitantes a leads é chave para melhorar suas vendas!`,
                      `Agora, qual tipo de solução você prefere?`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      {
                        title: 'Custo-benefício',
                        description: `Solução mais econômica e rápida a partir de um modelo pré-definido.`,
                      },
                      {
                        title: 'Sob medida',
                        description: `Site com design e estrutura de raíz, de acordo com as sugestões do cliente.`,
                      },
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {/* Start 3.1 / 3.2 flow */}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Intranet', 'Landing Page'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 5 && (
              <>
                <div className='row'>
                  <SimpleSelectTwo
                    question={[
                      `Obrigado pela resposta! Não vai demorar muito, eu prometo.`,
                      `Agora, você vai querer registrar um domínio conosco ou já tem um pronto?`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      'Pretendo registrar domínio',
                      'Já tenho um domínio',
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Intranet', 'Landing Page'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 6 && (
              <>
                <div className='row'>
                  <SimpleSelect
                    question={[
                      `Obrigado pela resposta!`,
                      `O seu sistema vai suportar um ou mais idiomas?`,
                    ]}
                    setState={this.upDateField}
                    options={['Um idioma', 'Dois idiomas', 'Três ou mais']}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Intranet', 'Landing Page'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 7 && (
              <>
                <div className='row'>
                  <SimpleSelect
                    question={[
                      `Obrigado pela resposta! Estamos quase no fim!`,
                      `Agora, me fala sobre o prazo. Pra quando você planeja ter seu site pronto?`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      'Sem data definida',
                      'Até 10 dias (urgente)',
                      'Áte 30 dias',
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}
          {/* End 3.1 / 3.2 flow */}

          {/* Start 3.3 / 3.4 flow */}

          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Institucional', 'Ecommerce'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 5 && (
              <>
                <div className='row'>
                  <TextInput
                    question={[
                      `Obrigado pela resposta! Não vai demorar muito, eu prometo.`,
                      `Agora, você pode me dar alguns sites como referências? Podem ser até concorrentes.`,
                      `Não é obrigatório, mas referências ajudam muito a agilizar o desenvolvimento!`,
                    ]}
                    setState={this.upDateField}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Institucional', 'Ecommerce'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 6 && (
              <>
                <div className='row'>
                  <SimpleSelectTwo
                    question={[
                      `Obrigado pela resposta! Referências nos ajuda a entender e atender às suas expectativas.`,
                      `Não vai demorar muito, eu prometo.`,
                      `Agora, você vai querer registrar um domínio conosco ou já tem um pronto?`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      'Pretendo registrar domínio',
                      'Já tenho um domínio',
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Institucional'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 7 && (
              <div className='row'>
                <div className='field col-sm'>
                  <QuestionHeader
                    texts={[
                      `Entendido!`,
                      `Voce deseja ter algum desses serviços extras no seu site?`,
                    ]}
                  />
                  <div className='container'>
                    <ServiceListFive
                      question={[
                        `Entendido!`,
                        `Voce deseja ter algum desses serviços extras no seu site?`,
                      ]}
                      setState={this.upDateField}
                      item='3'
                      column='col-12 col-sm-12 col-md-6 col-lg-4 text-left'
                    />
                  </div>
                  <ContinueButton
                    text='continuar'
                    callback={() => this.goToNextQuestion()}
                  />
                </div>
              </div>
            )}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Ecommerce'].includes(this.state.chosenOptions[2]?.answer[0]) &&
            this.state.step === 7 && (
              <>
                <div className='row'>
                  <SimpleSelectMultiSelection
                    question={[
                      `Entendido!`,
                      `Voce deseja ter algum desses serviços extras no seu site?`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      'Integração com Sistema faturamento',
                      'Cupons de descontos',
                      'Lista de favoritos',
                      'Blog e notícias',
                      'Newsletter',
                      'Live chat',
                      'Importação por XLM ou CVS',
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Institucional', 'Ecommerce'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 8 && (
              <>
                <div className='row'>
                  <SimpleSelect
                    question={[
                      `entendido`,
                      `Estamos terminando`,
                      `O seu site vai suportar um ou mais idiomas?`,
                    ]}
                    setState={this.upDateField}
                    options={['Um idioma', 'Dois idiomas', 'Três ou mais']}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}
          {this.state.chosenOptions[0]?.answer[0] === 'Websites e ecommerce' &&
            ['Site Institucional', 'Ecommerce'].includes(
              this.state.chosenOptions[2]?.answer[0]
            ) &&
            this.state.step === 9 && (
              <>
                <div className='row'>
                  <SimpleSelect
                    question={[
                      `Obrigado pela resposta! Estamos quase no fim!`,
                      `Agora, me fala sobre o prazo. Pra quando você planeja ter seu site pronto?`,
                    ]}
                    setState={this.upDateField}
                    options={[
                      'Sem data definida',
                      'Até 10 dias (urgente)',
                      'Áte 30 dias',
                    ]}
                  />
                </div>
                <ContinueButton
                  text='continuar'
                  isDisabled={this.isEmpty(this.state.currentQuestion)}
                  callback={() => this.goToNextQuestion()}
                />
              </>
            )}

          {this.checkIfItsTheLastQuestion() && (
            <>
              <div className='row'>
                <div className='field col-sm'>
                  <QuestionHeader
                    texts={[
                      `Obrigado pela resposta! Estamos quase no fim!`,
                      `Finalmente terminamos! Foi mais rápido do que você esperava, não é?`,
                      `Como você prefere que nós possamos entrar em contato com você?`,
                    ]}
                  />
                  <div className='container'>
                    <SocialMediaList
                      setState={this.upDateField}
                      question={[
                        `Obrigado pela resposta! Estamos quase no fim!`,
                        `Finalmente terminamos! Foi mais rápido do que você esperava, não é?`,
                        `Como você prefere que nós possamos entrar em contato com você?`,
                      ]}
                      item='3'
                      column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
                    />
                  </div>
                  <div className='input-field'>
                    <input
                      placeholder='Seu nome'
                      onChange={(event) => this.changeUserName(event)}
                      required
                    />
                    <input
                      placeholder='Seu email'
                      type='email'
                      onChange={(event) => this.changeUserEmail(event)}
                    />

                    <input
                      placeholder='Seu telefone'
                      type='tel'
                      onChange={(event) => this.changeUserPhone(event)}
                    />
                  </div>
                  <SendEmailButton
                    text='enviar'
                    isLoading={isLoading}
                    isDisabled={
                      this.isEmpty(this.state.currentQuestion) ||
                      this.isEmpty(this.state.userInfo) ||
                      this.checkIfAnswerIsCorrect()
                    }
                    callback={this.sendEmail}
                  />
                </div>
              </div>
            </>
          )}
          {/* End 3.3 / 3.4 flow */}

          {/* End 3.0 flow */}
        </div>

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Budget;
