import React, { Component } from 'react';

import App from '../../../public/assets/images/icons/apple.svg';
import Website from '../../../public/assets/images/icons/android.svg';
import Huawei from '../../../public/assets/images/icons/huawei.svg';
import { ReactSVG } from 'react-svg';

const HostingList = [
  {
    icon: App,
    title: 'Apple App Store',
    option: 'Apple App Store',
  },
  {
    icon: Website,
    title: 'Google Play Store',
    option: 'Google Play Store',
  },
  {
    icon: Huawei,
    title: 'Huaweii App Gallery',
    option: 'Huaweii App Gallery',
  },
];

class Hosting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOptions: [],
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(option) {
    if (this.state.activeOptions.includes(option)) {
      let optionsCopy = this.state.activeOptions;
      optionsCopy = optionsCopy.filter((optionCopy) => optionCopy !== option);

      this.setState({
        activeOptions: optionsCopy,
      });
      this.props.setState(optionsCopy, this.props.question);
    } else {
      this.setState({
        activeOptions: [...this.state.activeOptions, option],
      });
      this.props.setState(
        [...this.state.activeOptions, option],
        this.props.question
      );
    }
  }
  render() {
    const { column } = this.props;
    const ServiceContent = HostingList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className='row'>
          {ServiceContent.map((val, i) => (
            <div className={`${column} mt--30`} key={i}>
              <button
                className={`select-button ${
                  this.state.activeOptions.includes(val.option) && 'active'
                }`}
                onClick={() => {
                  this.toggleActive(val.option);
                }}
              >
                <div className='service service__style--2 d-flex align-items-center bg_color--5'>
                  <div className='icon margin-reset'>
                    <ReactSVG src={val.icon} />
                  </div>
                  <div className='content'>
                    <h3 className='title margin-reset'>{val.title}</h3>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default Hosting;
