import React, { Component } from 'react';

import Whatsapp from '../../../public/assets/images/icons/whatsapp.svg';
import Phone from '../../../public/assets/images/icons/phone.svg';
import Email from '../../../public/assets/images/icons/email.svg';
import { ReactSVG } from 'react-svg';

const HostingList = [
  {
    icon: Phone,
    title: 'Ligação telefônica',
    option: 'Ligação telefônica',
  },
  {
    icon: Whatsapp,
    title: 'Whatsapp',
    option: 'Whatsapp',
  },
  {
    icon: Email,
    title: 'Email',
    option: 'Email',
  },
];

class SocialMediaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: '',
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(option) {
    this.setState({
      activeOption: option,
    });
  }

  render() {
    const { column, setState, question } = this.props;
    const ServiceContent = HostingList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className='row'>
          {ServiceContent.map((val, i) => (
            <div className={`${column} mt--30`} key={i}>
              <button
                className={`select-button ${
                  this.state.activeOption === val.option && 'active'
                }`}
                onClick={() => {
                  setState(val.option, question);
                  this.toggleActive(val.option);
                }}
              >
                <div className='service service__style--2 minWidth d-flex align-items-center bg_color--5'>
                  <div className='icon icon-color-2'>
                    <ReactSVG src={val.icon} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>{val.title}</h3>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default SocialMediaList;
