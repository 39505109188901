import React, { Component } from 'react';
import QuestionHeader from './QuestionHeader';

class SimpleSelect extends Component {
  render() {
    const { question, setState } = this.props;

    return (
      <React.Fragment>
        <div className='field col-sm'>
          <QuestionHeader texts={question} />
          <div className='input-field col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <textarea
              rows={4}
              cols={10}
              placeholder='Escreva aqui'
              onChange={(event) => setState(event.target.value, question)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SimpleSelect;
