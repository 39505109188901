import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { slideSlick } from '../page-demo/script';
import ServiceList from '../elements/service/ServiceList';
import Header from '../component/header/Header';
import FooterTwo from '../component/footer/FooterTwo';
import Helmet from '../component/common/Helmet';
import BrandTwo from '../elements/BrandTwo';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton} from 'react-accessible-accordion';


import { BsFillCaretRightFill, BsFillCaretDownFill } from "react-icons/bs";


// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const SlideList = [
  {
    textPosition: 'text-left',
    bgImage: 'bg_image--33',
    category: '',
    title: 'Desenvolvimento',
    description:
      'Estamos sempre disponíveis para ajudá-lo a encontrar a melhor solução para o seu problema.',
    buttonText: 'solicite um orçamento',
    buttonLink: '/budget',
  },
  {
    textPosition: 'text-left',
    bgImage: 'bg_image--34',
    category: '',
    title: 'Design',
    description:
      'Estamos sempre disponíveis para ajudá-lo a encontrar a melhor solução para o seu problema.',
    buttonText: 'solicite um orçamento',
    buttonLink: '/budget',
  },
];


var getOffsetTop = function (elementId) {
  var elem = document.getElementById(elementId);
  //get the distance scrolled on body (by default can be changed)
  var distanceScrolled = document.body.scrollTop;
  //create viewport offset object
  var elemRect = elem.getBoundingClientRect();
  //get the offset from the element to the viewport
  var elemViewportOffset = elemRect.top;
  //add them together
  var totalOffset = distanceScrolled + elemViewportOffset;

  return totalOffset
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: 2,
      howWeWorkIndex: "accordion-item-0"
    };
    this.setImageIndex = this.setImageIndex.bind(this);
  }

  handleScroll(event) {
    const serviceAreaOffset = getOffsetTop("service");

    if (serviceAreaOffset <= (document.getElementById("service").scrollHeight / 2)) {
      const icons = document.getElementsByClassName("icon");
      const contents = document.getElementsByClassName("content");

      for (let i = 0; i < icons.length; i++) {
        icons[i].className = "icon opacity-1";
      }

      for (let j = 0; j < contents.length; j++) {
        contents[j].className = "content content-remove-margin opacity-1";
      }

    }
  }

  setImageIndex(index) {
    this.setState({ imageIndex: index });
  }

  setHowWeWorkIndex(index){
    this.setState({howWeWorkIndex: index})
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');

    let width, height, lastNow;
    let snowflakes;
    const maxSnowflakes = 50;

    window.addEventListener("scroll", this.handleScroll);

    function init() {
      snowflakes = [];
      resize();
      render((lastNow = performance.now()));
    }

    function render(now) {
      requestAnimationFrame(render);

      const elapsed = now - lastNow;
      lastNow = now;

      ctx.clearRect(0, 0, width, height);
      if (snowflakes.length < maxSnowflakes) snowflakes.push(new Snowflake());

      ctx.fillStyle = ctx.strokeStyle = '#ffffff4d';

      snowflakes.forEach((snowflake) => snowflake.update(elapsed, now));
    }

    function pause() {
      cancelAnimationFrame(render);
    }

    function resume() {
      lastNow = performance.now();
      requestAnimationFrame(render);
    }

    class Snowflake {
      constructor() {
        this.spawn();
      }

      spawn(anyY = false) {
        this.x = rand(0, width);
        this.y = anyY === true ? rand(height + 50, -50) : rand(-10, -50);
        this.xVel = rand(-0.05, 0.05);
        this.yVel = rand(0.01, 0.04);
        this.angle = rand(0, Math.PI * 5);
        this.angleVel = rand(-0.001, 0.001);
        this.size = rand(7, 12);
        this.sizeOsc = rand(0.01, 0.5);
      }

      update(elapsed, now) {
        const xForce = rand(-0.001, 0.001);

        if (Math.abs(this.xVel + xForce) < 0.075) {
          this.xVel += xForce;
        }

        this.x += this.xVel * elapsed;
        this.y += this.yVel * elapsed;
        this.angle += this.xVel * -0.05 * elapsed; //this.angleVel * elapsed

        if (
          this.y - this.size > height ||
          this.x + this.size < 0 ||
          this.x - this.size > width
        ) {
          this.spawn();
        }

        this.render();
      }

      render() {
        ctx.save();
        const { x, y, size } = this;
        ctx.beginPath();
        ctx.arc(x, y, size * 0.2, 0, Math.PI * 2, false);
        ctx.fill();
        ctx.restore();
      }
    }

    // Utils
    const rand = (min, max) => min + Math.random() * (max - min);

    function resize() {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
    }

    window.addEventListener('resize', resize);
    window.addEventListener('blur', pause);
    window.addEventListener('focus', resume);
    init();
  }

  render() {
    return (
      <Fragment>
        <Helmet pageTitle='SoftMakers' />

        {/* Start Header Area  */}
        <Header color='color-white' />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className='slider-wrapper color-white bg_image bg_image--33' id='home'>
          <canvas className='snow-flakes'></canvas>
          <div className='slider-activation slider-digital-agency'>
              <Slider className='rn-slick-dot dot-light' {...slideSlick}>
                {SlideList.map((value, index) => (
                  <div
                    className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                    key={index}
                    data-black-overlay='2'
                  >
                    <div className='container'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className={`inner ${value.textPosition}`}>
                            {value.title ? (
                              <h1 className='title'>{value.title}</h1>
                            ) : (
                              ''
                            )}
                            {value.description ? (
                              <p className='description'>{value.description}</p>
                            ) : (
                              ''
                            )}
                            {value.buttonText ? (
                              <div className='slide-btn'>
                                <Link
                                  className='rn-button-style--2 btn-primary-color border-default'
                                  to={`${value.buttonLink}`}
                                >
                                  {value.buttonText}
                                </Link>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div
          className='service-area creative-service-wrapper ptb--120 bg_color--1'
          id='service'
        >
          <div className='container'>
            <div className='row creative-service'>
              <div className='col-lg-12'>
                <div className='section-title text-center service-style--3 mb--30'>
                  <h2 className='title'>Nossos Serviços</h2>
                  <p>Para revolucionar o seu negócio hoje</p>
                </div>
              </div>
            </div>
            <ServiceList
              item='3'
              column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        <div className='rn-about-area ptb--120 bg_color--5'>
          <div className='container' id='about'>
            <div className='row row--35 align-items-center'>
              <div className='col-lg-6'>
                <div className='thumbnail'>
                  {this.state.imageIndex === 1 && (
                    <img
                      className='w-100'
                      src='/assets/images/team/howWeWork1.jpeg'
                      alt='About Images'
                    />
                  )}
                  {this.state.imageIndex === 2 && (
                    <img
                      className='w-100'
                      src='/assets/images/team/howWeWork2.jpeg'
                      alt='About Images'
                    />
                  )}
                  {this.state.imageIndex === 3 && (
                    <img
                      className='w-100'
                      src='/assets/images/team/howWeWork3.jpeg'
                      alt='About Images'
                    />
                  )}
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='about-inner inner'>
                  <div className='section-title'>
                    <h3 className='title lign-height'>Como nós trabalhamos</h3>
                    <p>Empenho, beleza, criatividade e sucesso.</p>
                  </div>
                  <div className='accordion-wrapper mt--30'>
                    <Accordion
                      className='accodion-style--1'
                      preExpanded={["accordion-item-0"]}
                      onChange={(option) => {
                          this.setHowWeWorkIndex(option[0]);

                          if (option[0].indexOf("-0") !== -1) {
                            this.setImageIndex(2)
                          } else {
                            this.setImageIndex(3)
                          }
                        }
                      }
                    >
                      <AccordionItem uuid={"accordion-item-0"}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {(this.state.howWeWorkIndex === "accordion-item-0") ? <BsFillCaretDownFill /> : <BsFillCaretRightFill />} Analisamos e imergimos
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            A resolução para o seu problema começa a
                            aproximar-se assim que entra em contacto connosco.
                            Reunimo-nos o mais cedo possível para melhor
                            compreender as suas necessidades e definimos o
                            período de tempo e a forma como a solução será
                            desenvolvida.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem uuid={"accordion-item-1"}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {(this.state.howWeWorkIndex === "accordion-item-1") ? <BsFillCaretDownFill /> : <BsFillCaretRightFill />} Desenvolvemos e entregamos
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            O nosso objetivo é apresentar uma solução o mais
                            rápido possível, sempre com qualidade. Após
                            entendermos o que precisa de ser feito, colocamos as
                            mãos à massa! Através de metodologias ágeis,
                            “confecionaremos” o que for necessário e faremos de
                            tudo para que comece a melhorar os seus negócios
                            muito brevemente.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area ptb--120" id='projects'>
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--15">
                                        <h2 className="title">Nossos projetos</h2>
                                        <p>Cases e projetos que tivemos o prazer de trabalhar. Amizades e parcerias construídas modelando e expandindo pequenas, médias e grandes empresas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="3" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-primary-color" href="#"><span>veja mais</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Portfolio Area */}

        {/* Start budget Area */}
        <div className='rn-about-area ptb--120 bg_color--5'>
          <div className='container'>
            <div className='row row--35 align-items-center'>
              <div className='col-lg-6'>
                <div className='about-inner inner text-center'>
                  <div className='section-title text-left'>
                    <h3 className='title text-center'>
                      Faça um orçamento interativo agora mesmo
                    </h3>
                    <p className="text-center">Não será um formulário chato e longo, nós prometemos!</p>
                  </div>
                  {/* <Link className="rn-button-style--2 btn-primary-color" to='/budget' >{value.buttonText}</Link> */}
                  <div className='slide-btn'>
                    <Link
                      className='rn-button-style--2 rn-button-style--3 btn-primary-color'
                      to='/budget'
                    >
                      solicite um orçamento
                    </Link>
                  </div>
                  <div className='about-inner about-inner-mt2 inner text-left'>
                    <div className='mt--30'>
                      <h4>Endereço</h4>
                      <ul className='list-style--1'>
                        <li>
                          Av. Adjar da Silva Casé, 800, Coworking Sala 04 -
                          Indianópolis, Caruaru - PE, 55024-740
                        </li>
                      </ul>
                    </div>
                    <div className='mt--30'>
                      <h4>Telefone</h4>
                      <ul className='list-style--1'>
                        <li>+55 81 98183-2649</li>
                      </ul>
                    </div>
                    <div className='mt--30'>
                      <h4>Email</h4>
                      <ul className='list-style--1'>
                        <li>contato@softmakers.pt</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='thumbnail'>
                  <img
                    className='w-100'
                    src='/assets/images/bg/budget.jpeg'
                    alt='About Images'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End budget Area */}

        {/* Start Brand Area */}
        <div className='rn-brand-area bg_color--1 ptb--60'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-12'>
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Home;
