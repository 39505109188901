import React, { Component } from 'react';
import QuestionHeader from './QuestionHeader';

class SimpleSelectMultiSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOptions: [],
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(option) {
    if (this.state.activeOptions.includes(option)) {
      let optionsCopy = this.state.activeOptions;
      optionsCopy = optionsCopy.filter((optionCopy) => optionCopy !== option);
      this.setState({
        activeOptions: optionsCopy,
      });
      this.props.setState(optionsCopy, this.props.question);
    } else {
      this.setState({
        activeOptions: [...this.state.activeOptions, option],
      });
      this.props.setState(
        [...this.state.activeOptions, option],
        this.props.question
      );
    }
  }

  render() {
    const { question, options } = this.props;

    return (
      <React.Fragment>
        <div className='field col-sm'>
          <QuestionHeader texts={question} />
          <div className='select'>
            <div className='container select-field'>
              {options.map((option, index) => (
                <button
                  key={index}
                  className={`select-button ${
                    this.state.activeOptions.includes(option) &&
                    'activeSimpleButton'
                  } col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mr-3`}
                  onClick={() => {
                    this.toggleActive(option);
                  }}
                >
                  <span>{option}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SimpleSelectMultiSelection;
