import React, { Component, Fragment } from 'react';

import Helmet from '../component/common/Helmet';
import { Link } from 'react-router-dom';

class Greetings extends Component {
  render() {
    return (
      <Fragment>
        <Helmet pageTitle='SoftMakers' />

        {/* Start Slider Area   */}
        <div className='color-white'>
          <div className='greeting-container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className={`text-center`}>
                  <h1 className='greeting-title'>
                    OBRIGADO <br /> PELA SUA PACIÊNCIA
                  </h1>
                  <p className='greeting-description'>
                    Entraremos em contato o quanto antes
                  </p>
                  <div className='slide-btn'>
                    <Link
                      className='rn-button-style--2 btn-primary-color'
                      to='/'
                    >
                      Fechar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Greetings;
