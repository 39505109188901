import React, { Component } from 'react';

class SimpleSelect extends Component {
  render() {
    const { texts } = this.props;

    return (
      <React.Fragment>
        <div className='user ptb--60'>
          <img
            src={`https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8&auto=format&fit=crop&w=500&q=60https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8&auto=format&fit=crop&w=500&q=60`}
            alt=''
          />
          <div className='col-10 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            {texts?.map((value, index) => (
              <p index={index}>{value}</p>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SimpleSelect;
